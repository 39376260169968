<template>
  <div class="container">
    <div class="row">
      <h1 class="col-md-12 text-center page-title">
        Shasthra Security Service
      </h1>
    </div>
  </div>
  <!-- end.container -->

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <h4>
          The areas which SSI Protection provides security services include but
          are not limited to:
        </h4>

        <br />

        <ul class="col-md-6">
          <li>Commercial Locations</li>
          <li>Senior Citizens Facilities</li>
          <li>Residential Locations</li>
          <li>Constructions Sites</li>
          <li>Retail Organizations</li>
          <li>Government Contracts</li>
          <li>Conventions</li>
          <li>Restaurants</li>
          <li>Fairs</li>
        </ul>

        <ul class="col-md-6">
          <li>Sporting Events</li>
          <li>Educational Facilities</li>
          <li>Special Events</li>
          <li>Financial Institutions</li>
          <li>Medical Facilities</li>
          <li>Hotels</li>
          <li>Entertainment Venues</li>
          <li>Industrial Locations</li>
          <li>Airports</li>
        </ul>
      </div>

      <div class="col-md-8 col-md-offset-2">
        <br />

        <h4>Personal Protection Specialists</h4>

        <p>
          We provide detail-oriented protection agents who bring together a
          variety of experiences ranging from military, diplomatic protection,
          and veteran armed security officers. Protection specialists are
          carefully chosen in order to provide client needs so they can go about
          their business and personal engagements in a secure manner. Whether
          the client be foreign dignitaries, diplomats, or high-profile
          families, our agents can provide the desired security through a range
          of skill-sets in order to deter, detect, or respond to threats. Our
          protection specialists aren't formed for the client at the last
          minute. Our specialists regularly work and train together so they are
          continuously familiar with each other in order to provide the best and
          most reactive detail to the client.
        </p>
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
export default {
  name: "Services",
};
</script>
