<template>
  <div class="container">
    <div class="row">
      <h1 class="col-md-12 text-center page-title">Security Training</h1>
    </div>
  </div>
  <!-- end.container -->

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <h4>Our Responsibility to Train and Develop</h4>

        <p>
          At Shasthra, we understand that it is just not enough to identify what
          our clients want, but more importantly what they need. We have found
          service that focuses on what makes our clients happy isn’t enough:
          analyzing and distinguishing their fundamental need for security is
          almost more important. This commitment helps ensure that we are one
          step ahead in fulfilling the needs of our customer.
        </p>

        <br />

        <h4>Our Presence</h4>

        <p>
          Security needs to be present and visible without getting in the way.
          Being aware of what our clients see as opportunities and threats help
          our officers better serve you. Their ability to discern the needs and
          not just the wants help manage the vision and purpose that our client
          sees for the site. This includes the perception that both the
          employees and visitors of the site experience on a daily basis.
        </p>

        <!-- <div class="embed-responsive embed-responsive-16by9">
          <iframe
            src="//player.vimeo.com/video/109993862?title=0&amp;byline=0&amp;portrait=0&amp;color=f8c144"
            width="500"
            height="281"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div> -->

        <br />

        <h5>We also train and develop our officers on the following areas.</h5>

        <br />

        <ui class="col-md-6">
          <li>Handcuffing</li>
          <li>Mace</li>
          <li>Patrolling</li>
          <li>Policy &amp; Procedures</li>
          <li>Report Writing</li>
          <li>Proper Uniform care and wear</li>
          <li>Sexual Harassment</li>
          <li>Entry-level training</li>
          <li>Introduction to security</li>
          <li>CPR First Aid</li>
          <li>Basic Law for Security officer</li>
          <li>Confidential information</li>
          <li>Report written</li>
          <li>How to handle aggressive behavior</li>
          <li>Enforcing rules and Regulations</li>
          <li>Emergency Situation Procedures</li>
        </ui>

        <ui class="col-md-6">
          <li>Crime identification</li>
          <li>Parking &amp; Traffic Procedures</li>
          <li>Escort Procedures</li>
          <li>Officer Safety</li>
          <li>Hazardous Materials 1st Responder</li>
          <li>Fire Control</li>
          <li>Counter-Terrorist Security</li>
          <li>Workplace Violence</li>
          <li>Crime Scene 1st Responder</li>
          <li>Controlled Dangerous Substances</li>
          <li>Communications &amp; Public Relation</li>
          <li>Company Policies &amp; Code of Conduct</li>
          <li>Asp Baton</li>
          <li>Fire Arms</li>
          <li>Private Detective Course</li>
        </ui>
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
export default {
  name: "Services",
};
</script>
