<template>
  <div id="main-wrapper">
    <header class="hidden-xs">
      <div class="header-top">
        <div class="container">
          <div class="row">
            <a id="logo-txt" href="/"><img src="/img/ssi-logo.png" alt="" /></a>
            <p id="quick-contact" class="pull-right">
              CALL US <a href="tel:(703) 349 2913">(703) 349 2913</a>
            </p>
          </div>
        </div>
      </div>

      <div class="header-nav">
        <a id="logo" class="text-center" href="/"
          ><img src="/img/shasthra-logo.png" alt=""
        /></a>

        <div class="container">
          <div class="row nav-items">
            <div class="nav-wrapper">
              <nav class="main-nav left-items pull-left">
                <ul class="pull-right">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <router-link to="/about">About Us</router-link>
                  </li>
                </ul>
              </nav>

              <nav class="main-nav right-items pull-left">
                <ul>
                  <li>
                    <router-link to="/services">Services</router-link>
                    <ul class="inner">
                      <li>
                        <router-link to="/services/security"
                          >Security</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/services/training-and-development"
                          >Training</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/contact">Contact</router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <!-- end.nav-items -->
        </div>
        <!-- end.container -->
      </div>
    </header>

    <header class="visible-xs">
      <p id="mobi-logo" class="text-center nogap">
        <a href="/"
          ><img
            class="img-responsive center-block"
            src="/img/shasthra-logo.png"
            alt=""
        /></a>
      </p>

      <div
        class="navbar navbar-default navbar-static-top visible-xs"
        role="navigation"
      >
        <div class="container">
          <div class="navbar-header">
            <a class="navbar-brand" href="/">SSI Protection</a>
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <!-- end.navbar-header -->

          <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <router-link to="/about">About Us</router-link>
              </li>
              <li>
                <router-link
                  to="#"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  >Services <b class="caret"></b
                ></router-link>
                <ul class="dropdown-menu">
                  <li><router-link to="/services">Services</router-link></li>
                  <li>
                    <router-link to="/services/security"
                      >Security Services</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/services/training-and-development"
                      >Training &amp; Development</router-link
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </div>
          <!-- end.navbar-collapse -->
        </div>
        <!-- end.container -->
      </div>
      <!-- end.navbar -->
    </header>

    <div class="visible-xs hidden-sm">
      <div class="container">
        <div style="padding: 6px 0; font-size: 15px; font-style: bold">
          CALL US <a href="tel:(703) 349 2913">(703) 349 2913</a>
        </div>
      </div>
    </div>

    <router-view />

    <div id="ramjay-promo" class="col-xs-12 text-center">
      <a class="visible-xs" href="http://ramjayinc.com/" target="_blank"
        ><span
          ><img class="m-logo" src="/img/ramjayinc-logo.png" alt=""
        /></span>
        <p>
          The ultimate service for your individual or organization’s
          transportation needs.
        </p></a
      >
      <a class="hidden-xs" href="http://ramjayinc.com/" target="_blank"
        ><p>
          <img src="/img/ramjayinc-logo.png" alt="" />The ultimate service for
          your individual or organization’s transportation needs.
        </p></a
      >
    </div>
  </div>
  <!-- end#main-wrapper -->

  <footer>
    <p id="copyright" class="text-center">
      Copyright &copy; {{ new Date().getFullYear() }} SSI Protection.
    </p>
    <p id="devs" class="text-center">
      Made with <span class="glyphicon glyphicon-heart"></span> by
      <a href="http://olionsoft.com.lk/" target="_blank">Olionsoft</a>
    </p>
  </footer>
</template>
