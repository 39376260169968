<template>
  <div class="container">
    <div class="row">
      <h1 class="col-md-12 text-center page-title">
        Shasthra Security Service
      </h1>
    </div>
  </div>
  <!-- end.container -->

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <p>
          All officers at SSI Protection are trained at certified DCJS (Dept. of
          Criminal Justice Services) academies. Officers receive training
          throughout the year to learn new technologies, reinforce post training
          and increase their certifications.
        </p>

        <br />

        <h3>Our Vision</h3>
        <p>To surpass the next expectation of your destination</p>

        <h3>Our Mission</h3>
        <p>
          Our mission is to set the standards high and help the companies not to
          settle for less than what good quality brings.
        </p>

        <br /><br />

        <ul>
          <li>
            Officers and supervisors of SSI Protection understand that customer
            service is a part of their security services. They operate with a
            spirit of "client ownership" -- if clients have a problems, it is
            our duty to help solve it.
          </li>
          <li>
            We have a 24/7 hotline to insure that our clients can reach us at
            any time.
          </li>
          <li>
            The Director of Operations at SSI Protection is a well-reputed and
            experienced police officer who guarantees an exceptional service and
            can be reached by phone should you have any questions or concerns in
            regards to this proposal.
          </li>
        </ul>
      </div>
    </div>
    <!-- end.row -->
  </div>
  <!-- end.container -->
</template>

<script>
export default {
  name: "About",
};
</script>
